import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ContentWrapper from "../components/content-wrapper"
import * as styles from "./contacto.module.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const heroImage = getImage(data.file);

  return (
    <Layout bigImage={true} title={siteTitle}>
      <Seo
        title="Contacto"
        description="Disponível para todos os esclarecimentos e projetos."
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <div className={styles.headlineContainer}>
        <GatsbyImage
          className={styles.heroImage}
          layout="fullWidth"
          image={heroImage}
          alt=""
        />
        <div className={styles.headerContent}>
          <ContentWrapper>
            <header className="post-content-header">
              <h1 className="post-content-title">Contacto</h1>
            </header>

            <p className="post-content-excerpt">
              Estou disponível para qualquer esclarecimento ou projeto.
            </p>
          </ContentWrapper>
        </div>
      </div>

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <ContentWrapper>
            <div className={styles.card}>
              <div>
                <div className={styles.info}>E-mail:</div>
                <div>robertoassis04@gmail.com</div>
              </div>
              <div>
                <div className={styles.info}>Telefone:</div>
                <div> +351 926 509 703</div>
              </div>
            </div>
            <p></p>
            <p>
              Se prefirir, utilize o seguinte formulário. Entrarei em contacto
              consigo o mais brevemente possível.
            </p>
            <div className={styles.card}>
              <form
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="contact"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <div className={styles.formRow}>
                  <label htmlFor="message-name">Nome *</label>
                  <input
                    required
                    type="text"
                    name="message-name"
                    id="message-name"
                    placeholder=""
                  />
                </div>

                <div className={styles.formRow}>
                  <label htmlFor="message-email">Email *</label>
                  <input
                    type="email"
                    required
                    name="message-email"
                    id="message-email"
                    placeholder=""
                  />
                </div>

                <div className={styles.formRow}>
                  <label htmlFor="message-phone">Telefone</label>
                  <input
                    type="text"
                    name="message-phone"
                    id="message-phone"
                    placeholder=""
                  />
                </div>

                <div className={styles.formRow}>
                  <label htmlFor="form-message">Mensagem</label>
                  <textarea
                    name="form-message"
                    id="form-message"
                    placeholder=""
                    rows={6}
                    defaultValue={""}
                  />
                </div>
                <div className={`${styles.formRow} ${styles.formRight}`}>
                  <button type="submit" className="primary">
                    Enviar Mensagem
                  </button>
                </div>

                <div className={`${styles.formRow} ${styles.info} `}>
                  <p>* Campos obrigatórios</p>
                </div>
              </form>
            </div>
          </ContentWrapper>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: {eq: "contactos.jpeg"}) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          placeholder: BLURRED
        )
      }
    }
  }
`

const ElementsStatic = (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => (
      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)

export default ElementsStatic
